
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";


import { ContactApi } from "../../api/ContactApi";



function Contact_form(){


  const[message, setmessage] = useState("")



  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      message: "",

    },
    validationSchema: Yup.object().shape(
      {
        name: Yup.string().required("Please enter name"),
        email: Yup.string().email('Please enter valid email').required("Please enter email"),
        contact: Yup.number().typeError("Please enter number only.").required("Please enter contact"),
        message: Yup.string().required("Please enter message"),
      },
      []
    ),
    onSubmit: (values) => {
      console.log(values);
      const data = new FormData();
      data.append("name", values.name);
      data.append("email", values.email);
      data.append("contact", values.contact);
      data.append("message", values.message);

      // api code here

      ContactApi.SendContactData(data)
            .then((res) => {
              if (res.data.status) {
                // formik.setFieldValue('name', '');
                // formik.setFieldValue('name', '');
                // formik.setFieldValue('name', '');
                // formik.setFieldValue('name', '');
                formik.resetForm();
                setmessage(res.data.message);
              } else {
                setmessage(res.data.message);
                
              }
            
      })
      .catch((error) => {
        console.log("error", error);
      });

    }
    });


    return(
     <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">

    <form
      onSubmit={formik.handleSubmit}
      className="php-email-form"
    >
      <div className="row">
        <div className="form-group col-md-6">
          <label htmlFor="name">Your Name</label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />

          {formik.touched.name &&
          formik.errors.name ? (
          <p className="text-danger text-small text-left">
          {formik.errors.name}
          </p>
          ) : null}
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="name">Your Email</label>
          <input
            type="text"
            className="form-control"
            name="email"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            
          />
           {formik.touched.email &&
          formik.errors.email ? (
          <p className="text-danger text-small text-left">
          {formik.errors.email}
          </p>
          ) : null}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="name">Contact</label>
        <input
          type="text"
          className="form-control"
          name="contact"
          id="contact"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.contact}
        />
         {formik.touched.contact &&
          formik.errors.contact ? (
          <p className="text-danger text-small text-left">
          {formik.errors.contact}
          </p>
          ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="name">Message</label>
        <textarea
          className="form-control"
          name="message"
          rows={10}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
        />
        {formik.touched.message &&
        formik.errors.message ? (
        <p className="text-danger text-small text-left">
        {formik.errors.message}
        </p>
        ) : null}
      </div>
      {/* <div className="my-3">
        <div className="loading">Loading</div>
        <div className="error-message" />
        <div className="sent-message">
          Your message has been sent. Thank you!
        </div>
      </div> */}
      <div>{message}</div>
      <div className="text-center">
        <button type="submit">Send Message</button>
      </div>
    </form>
  </div>)
 

};
export default Contact_form;
