


function Resume() {
    return(
        <section id="resume" className="resume">
        <div className="container">
          <div className="section-title">
            <h2>Resume</h2>
            {/* <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
              aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
              quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
              fugiat sit in iste officiis commodi quidem hic quas.
            </p> */}
          </div>
          <div className="row">
            <div className="col-lg-6" data-aos="fade-up">
              <h3 className="resume-title">Sumary</h3>
              <div className="resume-item pb-0">
                <h4>Amol Kharate</h4>
                {/* <p>
                  <em>
                    Innovative and deadline-driven Graphic Designer with 3+ years
                    of experience designing and developing user-centered
                    digital/print marketing material from initial concept to
                    final, polished deliverable.
                  </em>
                </p> */}
                <ul>
                  <li>Sr. Full Stack Developer at iLoma Technology Pvt Ltd</li>
                  <li> +91 9970426205</li>
                  <li>amolkharate.wwg@gmail.com</li>
                </ul>
              </div>
              <h3 className="resume-title">Education</h3>
              <div className="resume-item">
                <h4>INDIA ACTIVE SOFTWARE INC.</h4>
                <h5>2015 </h5>
                {/* <p>
                  <em>Rochester Institute of Technology, Rochester, NY</em>
                </p> */}
                <p>
                Web Development Course
                </p>
              </div>
              <div className="resume-item">
                <h4>City premier college</h4>
                <h5>2013 - 2014</h5>
                {/* <p>
                  <em>Rochester Institute of Technology, Rochester, NY</em>
                </p> */}
                <p>
                Post Graduate Diploma in Computer Commercial Application
                </p>
              </div>
              <div className="resume-item">
                <h4>Dhruv college of commerce & management</h4>
                <h5>2010-2013 </h5>
                {/* <p>
                  <em>Rochester Institute of Technology, Rochester, NY</em>
                </p> */}
                <p>
                Bachelor of Commerce Computer Application
                </p>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
              <h3 className="resume-title">Professional Experience</h3>
              <div className="resume-item">
                <h4>iLoma Technology Pvt. Ltd.</h4>
                <h5>Nov 2019 - Present</h5>
                <p>
                  <em>Sr. Full Stack  Developer </em>
                </p>
                {/* <ul>
                  <li>
                    Lead in the design, development, and implementation of the
                    graphic, layout, and production communication materials
                  </li>
                  <li>
                    Delegate tasks to the 7 members of the design team and provide
                    counsel on all aspects of the project.{" "}
                  </li>
                  <li>
                    Supervise the assessment of all graphic materials in order to
                    ensure quality and accuracy of the design
                  </li>
                  <li>
                    Oversee the efficient use of production project budgets
                    ranging from $2,000 - $25,000
                  </li>
                </ul> */}
              </div>
             
               <div className="resume-item">
                <h4>SRS Infosystems Pvt. Ltd.</h4>
                <h5>Oct 2018 – Oct 2019</h5>
                <p>
                  <em>Sr. Software Developer</em>
                </p>
                {/* <ul>
                  <li>
                    Developed numerous marketing programs (logos,
                    brochures,infographics, presentations, and advertisements).
                  </li>
                  <li>
                    Managed up to 5 projects or tasks at a given time while under
                    pressure
                  </li>
                  <li>
                    Recommended and consulted with clients on the most appropriate
                    graphic design
                  </li>
                  <li>
                    Created 4+ design presentations and proposals a month for
                    clients and account managers
                  </li>
                </ul> */}
              </div>
               <div className="resume-item">
                <h4>Codenet Infotech  Pvt. Ltd. </h4>
                <h5>Sept 2017 – Oct 2018</h5>
                <p>
                  <em>Web Developer</em>
                </p>
                {/* <ul>
                  <li>
                    Developed numerous marketing programs (logos,
                    brochures,infographics, presentations, and advertisements).
                  </li>
                  <li>
                    Managed up to 5 projects or tasks at a given time while under
                    pressure
                  </li>
                  <li>
                    Recommended and consulted with clients on the most appropriate
                    graphic design
                  </li>
                  <li>
                    Created 4+ design presentations and proposals a month for
                    clients and account managers
                  </li>
                </ul> */}
              </div>
              <div className="resume-item">
                <h4>Mirackle Solutions</h4>
                <h5>Sept 2015 – Aug 2017</h5>
                <p>
                  <em>Web Developer</em>
                </p>
                {/* <ul>
                  <li>
                    Developed numerous marketing programs (logos,
                    brochures,infographics, presentations, and advertisements).
                  </li>
                  <li>
                    Managed up to 5 projects or tasks at a given time while under
                    pressure
                  </li>
                  <li>
                    Recommended and consulted with clients on the most appropriate
                    graphic design
                  </li>
                  <li>
                    Created 4+ design presentations and proposals a month for
                    clients and account managers
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>

    )
};
export default Resume;