
import axios from "axios"
import config from "../config";
import * as url from "../url/helper_url";

export const ContactApi = {

SendContactData: async (data) => {
    var axiosConfig = {
        method: 'POST',
        url: `${config.API_URL}/${url.SEND_CONTACT_URL}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    return await axios(axiosConfig);
}
}
