



function About(props) {

    return(
       
    <section id="about" className="about">
      <div className="container">
        <div className="section-title">
          <h2>About</h2>
          <p>
          I'm Amol Kharate, a seasoned software engineer with over 7 years of experience in the industry. Throughout my career, I've had the privilege of working in diverse environments, ranging from small startups to large, established organizations. My primary expertise lies in crafting scalable backend services and robust API solutions.
          <br/>
          <br/>
          I take pride in my ability to write code that is not only efficient but also well-designed and highly testable, always staying up-to-date with the latest best practices in web development. I'm known for my quick learning capabilities, strong work ethic, and my commitment to collaborative teamwork. I'm proficient in a wide range of scripting languages and proficiently leverage multimedia web tools to drive results.
          <br/>
          <br/>
          If you have any specific questions or if there's anything you'd like to discuss related to your experience or career, please feel free to ask!
 
          </p>
        </div>
        <div className="row">
          <div className="col-lg-4" data-aos="fade-right">
            <img
              src="assets/img/1.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
            <h3>Sr. Full Stack  Developer</h3>
            {/* <p className="fst-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
            <div className="row">
              <div className="col-lg-6">
                <ul>
                <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <strong>Name:</strong> <span> Mr. Amol Kharate </span>
                  </li>
                 
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <strong>Website:</strong> <span>www.amolkharate.com</span>
                  </li>
                  {/* <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <strong>Phone:</strong> <span> +91 9970426205</span>
                  </li> */}
                  {/* <li>
                    <i className="bi bi-chevron-right" /> <strong>City:</strong>{" "}
                    <span>Nagpur, India</span>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                    <li>
                    <i className="bi bi-chevron-right" /> <strong>City:</strong>{" "}
                    <span>Nagpur, India</span>
                  </li>
                {/* <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <strong>Birthday:</strong> <span> 23 November 1991 </span>
                  </li> */}
                  {/* <li>
                    <i className="bi bi-chevron-right" /> <strong>Age:</strong>{" "}
                    <span>30</span>
                  </li> */}
                  <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <strong>Phone:</strong> <span> +91 9970426205</span>
                  </li>
                  {/* <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <strong>Degree:</strong> <span>Master</span>
                  </li> */}
                  {/* <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <strong>PhEmailone:</strong> <span>email@example.com</span>
                  </li> */}
                  {/* <li>
                    <i className="bi bi-chevron-right" />{" "}
                    <strong>Freelance:</strong> <span>Available</span>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* <p>
              Officiis eligendi itaque labore et dolorum mollitia officiis optio
              vero. Quisquam sunt adipisci omnis et ut. Nulla accusantium dolor
              incidunt officia tempore. Et eius omnis. Cupiditate ut dicta
              maxime officiis quidem quia. Sed et consectetur qui quia
              repellendus itaque neque. Aliquid amet quidem ut quaerat
              cupiditate. Ab et eum qui repellendus omnis culpa magni laudantium
              dolores.
            </p> */}
          </div>
        </div>
      </div>
    </section>
    

    )
};
export default About;