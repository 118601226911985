



function Services() {
    return(

        <section id="services" className="services">
        <div className="container">
          <div className="section-title">
            <h2>Services</h2>
            {/* <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
              aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
              quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
              fugiat sit in iste officiis commodi quidem hic quas.
            </p> */}
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
              <div className="icon">
                <i className="bi bi-briefcase" />
              </div>
              <h4 className="title">
                <>Software Development</>
              </h4>
              <p className="description">
                Software development services encompass designing, coding, testing, and maintaining software applications to meet specific business or user needs.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon">
                <i className="bi bi-card-checklist" />
              </div>
              <h4 className="title">
                <>Front End Developer</>
              </h4>
              <p className="description">
              Front-end developers create the visual and interactive elements of websites and web applications, ensuring a user-friendly and responsive user experience.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon">
                <i className="bi bi-bar-chart" />
              </div>
              <h4 className="title">
                <>Back End Developer</>
              </h4>
              <p className="description">
              Back-end developers build the server-side logic and database infrastructure that powers websites and applications, handling data processing and server communication.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="icon">
                <i className="bi bi-binoculars" />
              </div>
              <h4 className="title">
                <>SEO</>
              </h4>
              <p className="description">
              SEO (Search Engine Optimization) services improve a website's visibility on search engines like Google, aiming to increase organic traffic and rankings.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              <div className="icon">
                <i className="bi bi-brightness-high" />
              </div>
              <h4 className="title">
                <>Digital Marketing</>
              </h4>
              <p className="description">
              Digital marketing services encompass various online strategies and channels to promote businesses, products, or services to a wider audience and achieve marketing goals.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay={500}
            >
              <div className="icon">
                <i className="bi bi-calendar4-week" />
              </div>
              <h4 className="title">
                <>Ionic App Developer</>
              </h4>
              <p className="description">
              Ionic app developers specialize in creating cross-platform mobile applications using the Ionic framework, which leverages web technologies like HTML, CSS, and JavaScript etc.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
};
export default Services;