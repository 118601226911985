import react,{useEffect, useState} from 'react';
import Sidebar from "./layouts/Sidebar";
import About from  "./components/About";
import Resume  from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./layouts/Footer";
import Fact from "./components/Fact";




function Home() {

const [aboutdata, setAboutData] = useState({
   title:"About New",
   contactNo : "9970426205"
});

useEffect(()=>{
   setAboutData();   
},[])



return (
<div>

<Sidebar/>
<>
{/* ======= Hero Section ======= */}
<section
   id="hero"
   className="d-flex flex-column justify-content-center align-items-center"
   >
   <div className="hero-container" data-aos="fade-in">
      <h1>Amol Kharate</h1>
      <p>
         I'm Sr. Full Stack  Developer
         <span
            className="typed"
            data-typed-items="Designer, Developer, Freelancer, Photographer"
            />
      </p>
   </div>
</section>
{/* End Hero */}
<main id="main">
   {/* ======= About Section ======= */}
   <About data={aboutdata}/> 
   {/* End About Section */}
   {/* ======= Facts Section ======= */}
      <Fact/>
   {/* End Facts Section */}
   {/* ======= Skills Section ======= */}
   <section id="skills" className="skills section-bg">
      <div className="container">
      <div className="section-title">
         <h2>Skills</h2>
         <h3>
         These are some of the skills in which I excel and feel comfortable working with

         </h3>
      </div>
      {/* <div className="row skills-content">
      <div className="col-lg-6" data-aos="fade-up">
      <div className="progress">
      <span className="skill">
      HTML <i className="val">100%</i>
      </span>
      <div className="progress-bar-wrap">
         <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={100}
            aria-valuemin={0}
            aria-valuemax={100}
            />
         </div>
      </div>
      <div className="progress">
      <span className="skill">
      CSS <i className="val">90%</i>
      </span>
      <div className="progress-bar-wrap">
         <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={90}
            aria-valuemin={0}
            aria-valuemax={100}
            />
         </div>
      </div>
      <div className="progress">
         <span className="skill">
         JavaScript <i className="val">75%</i>
         </span>
         <div className="progress-bar-wrap">
            <div
               className="progress-bar"
               role="progressbar"
               aria-valuenow={75}
               aria-valuemin={0}
               aria-valuemax={100}
               />
            </div>
         </div>
      </div>
      <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
      <div className="progress">
         <span className="skill">
         PHP <i className="val">80%</i>
         </span>
         <div className="progress-bar-wrap">
            <div
               className="progress-bar"
               role="progressbar"
               aria-valuenow={80}
               aria-valuemin={0}
               aria-valuemax={100}
               />
            </div>
         </div>
         <div className="progress">
            <span className="skill">
            WordPress/CMS <i className="val">90%</i>
            </span>
            <div className="progress-bar-wrap">
               <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={90}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  />
               </div>
            </div>
            <div className="progress">
               <span className="skill">
               Photoshop <i className="val">55%</i>
               </span>
               <div className="progress-bar-wrap">
                  <div
                     className="progress-bar"
                     role="progressbar"
                     aria-valuenow={55}
                     aria-valuemin={0}
                     aria-valuemax={100}
                     />
                  </div>
               </div>
            </div>
         </div> */}
         <span>
         HTML5, CSS3, Javascript, Bootstrap, Angular, React, Node JS, PHP, Laravel, Symfony, Codeigniter, Cakephp, Wordpress, Opencart, Ionic(Basic), MySQL, PostgreSQL, SEO, Internet Marketing

         </span>
         <h4>

         </h4>


         <div>

         </div>
      </div>
      
      <div className="container mt-5">
      <div className="section-title">
         <h2>SERVER SKILL SET</h2>
         <h3>
         These are some of the Server  skills in which I excel and feel comfortable working with

         </h3>
         <ul>
            <li className='mt-3'>
            AWS - (Server Setup Installation, Domain & Subdomain connectivity, S3 Bucket, SSL Installation, Reverse Proxy Setting, SSH Connection,  Git Setup)

            </li>
            <li className='mt-3'>
            Digitalocean - (Server Setup Installation, Domain & Subdomain connectivity, S3 Bucket, SSL Installation, SSH Connection, Git Setup)

            </li>
            <li className='mt-3's>
            Many Other Shared & VPS Hosting (Manage Domain & Subdomain Connectivity, SSL Connection, Git Setup,  SSH Connection)

            </li>
         </ul>
      </div>
     {/* HTML5, CSS3, Javascript, Bootstrap, Angular, React, Node JS PHP, Laravel, Symfony, Codeigniter, Cakephp, Wordpress, Opencart, Ionic(Basic), MySQL, PostgreSQL, SEO, Internet Marketing */}

         <h4>

         </h4>


         <div>

         </div>
      </div>
   </section>
   {/* End Skills Section */}
   {/* ======= Resume Section ======= */}
   <Resume/>
   {/* End Resume Section */}
   {/* ======= Portfolio Section ======= */}
   {/* <Portfolio/> */}
   {/* End Portfolio Section */}
   {/* ======= Services Section ======= */}
   <Services/>
   {/* End Services Section */}
   {/* ======= Testimonials Section ======= */}
   {/* <section id="testimonials" className="testimonials section-bg">
      <div className="container">
         {/* <div className="section-title">
            <h2>Testimonials</h2>
            <p>
               Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
               aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
               quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
               fugiat sit in iste officiis commodi quidem hic quas.
            </p>
         </div> */}
         {/* <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay={100}
            >
            <div className="swiper-wrapper"> */}
               {/* <div className="swiper-slide">
                  <div className="testimonial-item" data-aos="fade-up">
                     <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Proin iaculis purus consequat sem cure digni ssim donec
                        porttitora entum suscipit rhoncus. Accusantium quam, ultricies
                        eget id, aliquam eget nibh et. Maecen aliquam, risus at
                        semper.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                     </p>
                     <img
                        src="assets/img/testimonials/testimonials-1.jpg"
                        className="testimonial-img"
                        alt=""
                        />
                     <h3>Saul Goodman</h3>
                     <h4>Ceo &amp; Founder</h4>
                  </div>
               </div>
               End testimonial item */}
               {/* <div className="swiper-slide">
                  <div
                     className="testimonial-item"
                     data-aos="fade-up"
                     data-aos-delay={100}
                     >
                     <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Export tempor illum tamen malis malis eram quae irure esse
                        labore quem cillum quid cillum eram malis quorum velit fore
                        eram velit sunt aliqua noster fugiat irure amet legam anim
                        culpa.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                     </p>
                     <img
                        src="assets/img/testimonials/testimonials-2.jpg"
                        className="testimonial-img"
                        alt=""
                        />
                     <h3>Sara Wilsson</h3>
                     <h4>Designer</h4>
                  </div>
               </div>
               End testimonial item
               <div className="swiper-slide">
                  <div
                     className="testimonial-item"
                     data-aos="fade-up"
                     data-aos-delay={200}
                     >
                     <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Enim nisi quem export duis labore cillum quae magna enim sint
                        quorum nulla quem veniam duis minim tempor labore quem eram
                        duis noster aute amet eram fore quis sint minim.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                     </p>
                     <img
                        src="assets/img/testimonials/testimonials-3.jpg"
                        className="testimonial-img"
                        alt=""
                        />
                     <h3>Jena Karlis</h3>
                     <h4>Store Owner</h4>
                  </div>
               </div>
               End testimonial item
               <div className="swiper-slide">
                  <div
                     className="testimonial-item"
                     data-aos="fade-up"
                     data-aos-delay={300}
                     >
                     <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                        multos export minim fugiat minim velit minim dolor enim duis
                        veniam ipsum anim magna sunt elit fore quem dolore labore
                        illum veniam.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                     </p>
                     <img
                        src="assets/img/testimonials/testimonials-4.jpg"
                        className="testimonial-img"
                        alt=""
                        />
                     <h3>Matt Brandon</h3>
                     <h4>Freelancer</h4>
                  </div>
               </div>
               End testimonial item
               <div className="swiper-slide">
                  <div
                     className="testimonial-item"
                     data-aos="fade-up"
                     data-aos-delay={400}
                     >
                     <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Quis quorum aliqua sint quem legam fore sunt eram irure aliqua
                        veniam tempor noster veniam enim culpa labore duis sunt culpa
                        nulla illum cillum fugiat legam esse veniam culpa fore nisi
                        cillum quid.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                     </p>
                     <img
                        src="assets/img/testimonials/testimonials-5.jpg"
                        className="testimonial-img"
                        alt=""
                        />
                     <h3>John Larson</h3>
                     <h4>Entrepreneur</h4>
                  </div>
               </div> */}
               {/* End testimonial item */}
            {/* </div>
            <div className="swiper-pagination" />
            </div>
         </div>
   </section> */} 
   {/* End Testimonials Section */}
   {/* ======= Contact Section ======= */}
   <Contact/>
   {/* End Contact Section */}
</main>
{/* End #main */}
{/* ======= Footer ======= */}
<Footer/>
{/* End  Footer */}
</>
</div>
);
}
export default Home;
