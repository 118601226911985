import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,  Route, Routes } from 'react-router-dom';
import './index.css';
// import App from './App';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import Sidebar from './pages/layouts/Sidebar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
   <BrowserRouter>
             <Routes>
                <Route exact path="/" element={<Home/>}/> 
                {/* <Route exact path="/about/:id/:status" element={<Sidebar/>}/> */}
             </Routes>  
  </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
