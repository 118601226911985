import React, { useState,useEffect } from 'react';
function Sidebar(){
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // useEffect(() => {
    //   menuActive();
    // },[])

    const menuActive = (e) => {
      if(!isMenuOpen) {
        //if flase (close)
        setIsMenuOpen(true);
      } else {
        //if true (open)
        setIsMenuOpen(false);
      }
    }

    return(
      <>
      {/* bi-x */}
      <i className={`bi bi-list mobile-nav-toggle d-xl-none ${(isMenuOpen) ? 'bi-x' : ''}`} onClick={menuActive} />
        <header id="header" className={(isMenuOpen) ? 'leftzero' : ''} >  
        {/* mobile-nav-active */}
        <div className="d-flex flex-column">
          <div className="profile">
            <img
              src="assets/img/1.png"
              alt=""
              className="img-fluid rounded-circle"
            />
            <h1 className="text-light">
              <a href="index.html"  >Amol Kharate</a>
            </h1>
            <div className="social-links mt-3 text-center">
              <a href="https://twitter.com/amolkharate3 " target="_blank" className="twitter">
                <i className="bx bxl-twitter" />
              </a>
              <a href="https://www.facebook.com/amol.kharate.98/" target="_blank" className="facebook">
                <i className="bx bxl-facebook" />
              </a>
              <a href="https://www.instagram.com/aamol.2311/" target="_blank" className="instagram">
                <i className="bx bxl-instagram" />
              </a>
              {/* <a href="#" target="_blank" className="google-plus">
                <i className="bx bxl-skype" />
              </a> */}
              <a href="https://www.linkedin.com/in/amol-kharate-81181494/"  target="_blank" className="linkedin">
                <i className="bx bxl-linkedin" />
              </a>
            </div>
          </div>
          <nav id="navbar" className="nav-menu navbar">
            <ul>
              <li>
                <a href="#hero" className="nav-link scrollto active">
                  <i className="bx bx-home" /> <span>Home</span>
                </a>
              </li>
              <li>
                <a href="#about" className="nav-link scrollto">
                  <i className="bx bx-user" /> <span>About</span>
                </a>
              </li>
              <li>
                <a href="#resume" className="nav-link scrollto">
                  <i className="bx bx-file-blank" /> <span>Resume</span>
                </a>
              </li>
              <li>
                <a href="#portfolio" className="nav-link scrollto">
                  <i className="bx bx-book-content" /> <span>Portfolio</span>
                </a>
              </li>
              <li>
                <a href="#services" className="nav-link scrollto">
                  <i className="bx bx-server" /> <span>Services</span>
                </a>
              </li>
              <li>
                <a href="#contact" className="nav-link scrollto">
                  <i className="bx bx-envelope" /> <span>Contact</span>
                </a>
              </li>
            </ul>
          </nav>
          {/* .nav-menu */}
        </div>
      </header>
      </>

    )
}
export default Sidebar;